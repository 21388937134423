<template>
    <div
      class="flex items-center justify-center bg-gray-100"
    >
      <div class="relative text-base min-w-10">
        <button
          class="flex items-center justify-between px-2 w-full rounded-lg gap-1 text-sm truncate"
          @click="isOptionsExpanded = !isOptionsExpanded"
          :class="customButtonClass"
          ref="buttonRef"
        >
          <span>{{ selectedOptionValueComputed }}</span>
          <CaretUpIcon class="h-4 w-4 transform transition-transform duration-200 ease-in-out" :class="isOptionsExpanded ? 'rotate-0' : 'rotate-180'"/>
        </button>
        <transition>
            <div ref="dropdownRef">
                <div class="absolute dropdown-content mt-1 flex flex-row bg-white shadow-lg z-10 scroll-custom-style"  v-show="isOptionsExpanded" :class="[customContentClass, {'active':isOptionsExpanded}]">
                    <div class="p-2" v-if="parentValue">
                        <ul>
                            <li
                                class="px-2 py-1 transition-colors duration-300 hover:bg-gray hover:bg-opacity-25 rounded text-sm font-bold cursor-default"
                                @mousedown.prevent="setOption(parentValue)"
                                :class="[{'active text-white bg-theme-4' : selectedOptionValueComputed == 'ทั้งประเทศ' || selectedOptionValueComputed == 'ทุกอำเภอ' || selectedOptionValueComputed == 'ทุกตำบล'}, multipleColumn ? 'w-[115px]' : 'min-w-[140px]']"
                            >
                                {{ parentValue[label] || parentValue }}
                            </li>
                        </ul>
                    </div>
                    <div class="w-full">
                        <slot name="dropdown-body"></slot>
                        <div class="flex">
                            <div class="p-2 border-right" :class="{'border-left' : parentValue}" v-for="(mainOption, index) in computedOptions" :key="index">
                                <div class="px-2 text-sm font-bold mb-1" v-if="mainOption.sector">{{mainOption.sector}}</div>
                                <div class="flex flex-row gap-1">
                                    <ul class="list-column" v-for="(option, index) in mainOption.lists" :key="index">
                                        <li
                                            v-for="(item, itemIndex) in option"
                                            :key="itemIndex"
                                            class="px-2 py-1 transition-colors duration-300 hover:bg-gray hover:bg-opacity-25 rounded text-sm font-normal cursor-default"
                                            @mousedown.prevent="setOption(item)"
                                            :class="[{'active text-white bg-theme-4' : (item[label] || item) == selectedOptionValueComputed}, multipleColumn ? 'w-[115px]' : 'min-w-[140px]']"
                                        >
                                            {{ item[label] || item }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
      </div>
    </div>
  </template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
const props = defineProps({
    options: Array,
    value: {default: null},
    parentValue: {type: [Object,String], default: null},
    label: {type:String},
    closeSelected: {type: Boolean, default: false},
    customButtonClass: Array,
    customContentClass: Array,
    multipleColumn: {type: Boolean, default: false}
})

const emits = defineEmits(['update:dropdown'])
const isOptionsExpanded = ref(false)
const dropdownRef = ref()
const buttonRef = ref()
const setOption = (option) => {
    if(props.closeSelected)
        isOptionsExpanded.value = false;
    emits('update:dropdown', option)
}

const selectedOptionValueComputed = computed({
    get() {
        return props.value?.[props.label] || props.value
    },
    set(evt) {
        emits("update:dropdown", evt);
    },
})

const handleClose = (e) => {
    if (!dropdownRef.value.contains(e.target) && !buttonRef.value.contains(e.target)) {
        isOptionsExpanded.value = false;
    }
}

const computedOptions = computed(()=>{
    if(props.options?.length){
        const perChunk = 14
        if(props.label == "PROVINCE_NAME_TH"){
            let newArr = [];
            let newGroup = props.options.reduce((group, item) => {
                const { SECTOR } = item;
                group[SECTOR] = group[SECTOR] ?? [];
                group[SECTOR].push(item);
                return group;
            }, {});

            for (const obj in newGroup) {
                const res = newGroup[obj].reduce((all,one,i) => {
                    const ch = Math.floor(i/perChunk);
                    all[ch] = [].concat((all[ch]||[]),one);
                    return all
                }, [])
                newArr.push({ sector: obj, lists: res });
            }
            let patternSort = {"ภาคเหนือ": 1, "ภาคตะวันออกเฉียงเหนือ": 2, "ภาคกลาง": 3, "ภาคตะวันออก": 4, "ภาคตะวันตก": 5, "ภาคใต้": 6}
            return newArr.sort((x,y)=> patternSort[x.sector] - patternSort[y.sector])
        }else{
            const resList = props.options.reduce((all,one,i) => {
                const ch = Math.floor(i/perChunk);
                all[ch] = [].concat((all[ch]||[]),one);
                return all
            }, [])
            const newResList = resList.map(item=>{
                return { lists: [item]}
            })
            return newResList
        }
    }
    return []
})
onMounted(()=>{
    window.addEventListener('click', handleClose)
})
onUnmounted(()=>{
    window.removeEventListener('click', handleClose)
})
</script>
<style lang="scss">
.ease-custom {
    transition-timing-function: cubic-bezier(.61,-0.53,.43,1.43);
}
.list-column{
    flex-direction: column;
    display: flex;
}
.border-right:not(:last-child){
    border-right: 1px solid #ccc
}
.border-left:first-child{
    border-left: 1px solid #ccc
}
.dropdown-content{
    &.province-filter{
        @media screen and (max-width: 860px){
            max-width: 620px;
            overflow: scroll;
        }
        @media screen and (min-width: 861px) and (max-width: 940px) {
            max-width: 700px;
            overflow: scroll;
        }
        @media screen and (min-width: 941px) and (max-width: 1023px) {
            max-width: 790px;
            overflow: scroll;
        }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
            max-width: 875px;
            overflow: scroll;
        }
        @media screen and (min-width: 1201px) and (max-width: 1320px) {
            max-width: 1020px;
            overflow: scroll;
        }

        //for mobile landscape
        @media screen and (min-width: 768px) and (max-width: 860px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape){
            max-width: 620px;
            overflow: scroll;
            max-height: 290px;
        }
        @media screen and (min-width: 861px) and (max-width: 940px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape) {
            max-width: 700px;
            overflow: scroll;
            max-height: 330px;
        }
        @media screen and (min-width: 900px) and (max-width: 1023px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape){
            max-width: 760px;
            overflow: scroll;
            max-height: 330px;
        }
    }
    &.amphur-filter{
        @media screen and (max-width: 840px){
            max-width: 400px;
            overflow: scroll;
        }
        @media screen and (min-width: 841px) and (max-width: 940px) {
            max-width: 530px;
            overflow: scroll;
        }
        @media screen and (min-width: 941px) and (max-width: 1023px) {
            max-width: 580px;
            overflow: scroll;
        }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
            max-width: 680px;
            overflow: scroll;
        }
        //for mobile landscape
        @media screen and (min-width: 768px) and (max-width: 860px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape){
            max-width: 500px;
            overflow: scroll;
            max-height: 290px;
        }
        @media screen and (min-width: 861px) and (max-width: 940px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape) {
            max-width: 550px;
            overflow: scroll;
            max-height: 330px;
        }
        @media screen and (min-width: 900px) and (max-width: 1023px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape){
            max-width: 570px;
            overflow: scroll;
            max-height: 330px;
        }
    }
    &.tambol-filter{
        @media screen and (max-width: 840px){
            max-width: 330px;
            overflow: scroll;
        }
        @media screen and (min-width: 841px) and (max-width: 940px) {
            max-width: 390px;
            overflow: scroll;
        }
        @media screen and (min-width: 768px) and (max-width: 860px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape){
            max-width: 330px;
            overflow: scroll;
            max-height: 290px;
        }
        @media screen and (min-width: 861px) and (max-width: 940px) and (min-height: 375px) and (max-height: 470px) and (orientation: landscape) {
            max-width: 430px;
            overflow: scroll;
            max-height: 330px;
        }
    }
}
</style>

