import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/app.scss'
import globalComponents from './global-components'

import 'vue-select/dist/vue-select.css';
const app = createApp(App).use(router)
globalComponents(app)
app.mount('#app')
