<template>
  <div class="overflow-x-auto">
    <table class="table w-full table-fixed" v-if="rowData">
      <thead>
        <tr>
          <th
            class="border dark:border-dark-5 whitespace-nowrap p-3"
            v-for="header in headerItems" :key="header"
          >
            <div class="flex justify-between">
              <span>{{ header.th }} </span>
              <div class="flex items-center">
                <CaretUpIcon class="w-4 h-4" :class="{'rotate-180': header.sort == 'desc'}" @click="sortColumn(header.key, header.sort == 'asc' ? 'desc' : 'asc'), header.sort = header.sort == 'asc' ? 'desc' : 'asc'"/>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        
        <tr v-for= "item in rowData" :key="item" >
          <td class="border dark:border-dark-5 p-3">
            {{item[headerItems[0].key]}}
          </td>
          <td class="border dark:border-dark-5 p-3">
            {{new Intl.NumberFormat().format(item[displayBy][fragile_level])}}
          </td>
        </tr>
        <tr class="!bg-gray !bg-opacity-10">
          <td class="border dark:border-dark-5 p-3">รวมทั้งหมด</td>
          <td class="border dark:border-dark-5 p-3">{{new Intl.NumberFormat().format(getTotalSum)}}</td>
        </tr>
      </tbody>
    </table>
    
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue"

const props = defineProps({
  dataArray: Array,
  header: {
    type: Array,
  },
  fragileLevel: Object,
  displayBy: String
})
const fragile_level = ref("fragile_total")

const rowData = ref([])
watch(()=> props.dataArray, (val) => {
  rowData.value = val.sort((a,b)=> a[props.header[0].key].localeCompare(b[props.header[0].key]));
})
watch(() => props.fragileLevel, (val)=>{
  rowData.value = rowData.value.map(item=> {
    let sum = 0
    for(let i of props.fragileLevel){
      sum += item[props.displayBy][i]
    }
    item[props.displayBy].fragile_total = sum
    return item
  }).sort((a,b)=> a[props.header[0].key].localeCompare(b[props.header[0].key]));
})

const headerItems = computed(()=> props.header)

const sortColumn = (sortBy, direction) => {
  switch(sortBy){
    case `${fragile_level.value}`:
      rowData.value = rowData.value.sort((a,b)=> (direction == 'asc' ? a: b)[props.displayBy][sortBy] - (direction == "asc" ? b : a)[props.displayBy][sortBy])
      break;
    default:
      rowData.value = rowData.value.sort((a,b)=> (direction == 'asc' ? a: b)[sortBy].localeCompare((direction == "asc" ? b : a)[sortBy]));

  }
}

const getTotalSum = computed(()=>{
  return rowData.value.reduce((acc, value)=>{
    return acc = acc + value[props.displayBy][fragile_level.value]
  }, 0)
})
</script>

<style lang="scss">
.table-row, .table-header{
  display: flex;
  flex-wrap: nowrap;
}
.table-header_item,
.table-column_item{
  width: 25%;
  border: solid 1px #4d4d4d;
  padding: 8px 16px;
  box-sizing: border-box;
}
tbody{
    tr{
      &:nth-child(even){
        background: #FFF;
      }
      &:nth-child(odd){
        background: #e6ebfe;
      }
    }
  }
</style>