<template>
    <nav class="navbar flex items-center">
        <div class="px-5">
            <div class="nav-left">
                <a class="nav-item site-logo" href="/">
                    <img src="@/assets/logo@2x.png">
                    <div class="text">Thai People Map and Analytics Platform </div>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {

}
</script>

<style>


</style>