<template>
  <div id="map-wrapper">
    <div id="heatmap-wrapper" class="h-full"></div>
  </div>
</template>

<script setup>
import { ref, watch, reactive } from "vue";
import * as L from "leaflet"
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css"


const fragile_level = ref("fragile_total")
const currentDisplayBy = ref("hh_cnt")
const showList = ref(false)
const loadingSuccess = ref(false)
const minMaxValue = reactive({
    min: null,
    max: null
})
const geoData = ref([])
const props = defineProps({
    geoData: Array,
    currentLevel: String,
    fragileLevel: Object,
    displayBy: String
})
const emits = defineEmits(["geoChange"])

const getColor = (count) => {
    if(count !== undefined){
        let min = minMaxValue.min
        let max = minMaxValue.max
        if (!max) {
            return "rgba(252, 110, 36, 0.1)";
        }
        if (!min) {
            min = 0;
        }
        let amount = max - min;
        let color_percent = 0;
        if (count - min !== 0) {
            color_percent = (count - min) / amount;
        }
        color_percent += 0.15;
        if (color_percent > 1) {
            color_percent = 1;
        }
        if(min === max)
            color_percent = 1
        return "rgba(252, 110, 36," + color_percent + ")";
    } else {
        return "rgba(252, 110, 36, 0.1)";
    }
}

const style = (feature) => {
    let total = 0
    total = feature.properties.fragile_obj?.[currentDisplayBy.value][fragile_level.value] ?? 0
    return {
        fillColor: getColor(total),
        weight: 1,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 1
    };
}

const highlightFeature = (e) => {
    var layer = e.target;
    layer.setStyle({
        weight: 4,
        color: '#FFF',
        dashArray: '',
        fillOpacity: 1
    });
    let tooltip_var
    let temp = ""
    if(props.fragileLevel.length > 1){
        temp += `<div class="text-[#2c67ff] font-bold">เปราะบางทั้งหมด ${new Intl.NumberFormat().format(layer.feature.properties.fragile_obj?.[currentDisplayBy.value].fragile_total ?? 0)} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}</div>`
    }
    if(props.fragileLevel.includes("fragile_lv0")){
        temp += `<div class="flex justify-between"><span>เปราะบางระดับ 0 </span><span class="ml-4">${new Intl.NumberFormat().format(layer.feature.properties.fragile_obj?.[currentDisplayBy.value].fragile_lv0 ?? 0)} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}</span></div>`
    }
    if(props.fragileLevel.includes("fragile_lv1")){
        temp += `<div class="flex justify-between"><span>เปราะบางระดับ 1 </span><span class="ml-4 text-end">${new Intl.NumberFormat().format(layer.feature.properties.fragile_obj?.[currentDisplayBy.value].fragile_lv1 ?? 0)} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}</span></div>`

    }
    if(props.fragileLevel.includes("fragile_lv2")){
        temp += `<div class="flex justify-between"><span>เปราะบางระดับ 2 </span><span class="ml-4 text-end">${new Intl.NumberFormat().format(layer.feature.properties.fragile_obj?.[currentDisplayBy.value].fragile_lv2 ?? 0)} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}</span></div>`
    }
    if(props.fragileLevel.includes("fragile_lv3")){
        temp += `<div class="flex justify-between"><span>เปราะบางระดับ 3 </span><span class="ml-4 text-end">${new Intl.NumberFormat().format(layer.feature.properties.fragile_obj?.[currentDisplayBy.value].fragile_lv3 ?? 0)} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}</span></div>`
    }
    if(props.currentLevel == "all"){
        tooltip_var = layer.bindTooltip(
                            `<div class="text-left heatmap-tooltip">
                                <b>${layer.feature.properties.PROVINCE_NAME_TH}</b>
                                ${temp}
                            </div>`
                        );
    }
    else if(props.currentLevel == "province"){
        tooltip_var = layer.bindTooltip(
                            `<div class="text-left heatmap-tooltip">
                                <b>${layer.feature.properties.AMPHUR_NAME_TH}</b>
                                ${temp}
                            </div>`
                        );
    }
    else if(props.currentLevel == "amphur" || props.currentLevel == "tambol"){
        tooltip_var = layer.bindTooltip(
                            `<div class="text-left heatmap-tooltip">
                                <b>${layer.feature.properties.TAMBOL_NAME_TH}</b>
                                ${temp}
                            </div>`
                        );
    }
    layer.bringToFront();
    tooltip_var.openTooltip();
    // info.update(layer.feature.properties);
}

var geojson;

const resetHighlight = (e) => {
    geojson.resetStyle(e.target);
    // info.update();
}
const renderNewMap = async (e) => {
    let shapeId = e.target.feature.properties,
    level
    if(props.currentLevel == "all"){
        level = "province"
    }
    else if(props.currentLevel == "province"){
        level = "amphur"
    }
    else if(props.currentLevel == "amphur"){
        level = "tambol"
    }
    else if(props.currentLevel == "tambol" && geoData.value.length > 1){
        level = "tambol"
    }
    if(shapeId){
        emits('geoChange', {properties: e.target.feature.properties, level: level})

    }
}

const onEachFeature = (feature, layer) => {
    layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: renderNewMap
    });
}

function getLat(dataMap) {
    let sumLat = 0;
    let centroidKey = "CENTROID_LATITUDE"
    if (dataMap && Array.isArray(dataMap)) {
        for (let item of dataMap) {
            sumLat += item.properties[centroidKey];
        }
    } else {
        sumLat = dataMap.properties[centroidKey];
    }
    sumLat = sumLat / dataMap.length;
    return Number(sumLat.toFixed(6));
}
function getLng(dataMap) {
    let sumLng = 0;
    let centroidKey = "CENTROID_LONGITUDE"
    if (dataMap && Array.isArray(dataMap)) {
        for (let item of dataMap) {
            sumLng += item.properties[centroidKey];
        }
    } else {
        sumLng = dataMap.properties[centroidKey];
    }
    sumLng = sumLng / dataMap.length;
    return Number(sumLng.toFixed(6));
}
function getMinMaxLatLng(dataMap) {
    let maxLat = -90,
        maxLng = -180,
        minLat = 90,
        minLng = 180;
    let tenth_latlng, data_each_coor;
    if (dataMap && Array.isArray(dataMap)) {
        for (let item of dataMap) {
            for (let item2 of item.geometry.coordinates[0]) {
                tenth_latlng = [item2[0]];

                for (let i = 0; i < 99; i++) {
                data_each_coor = item2[Math.round((item2.length / 100) * i)];
                    if (data_each_coor && typeof data_each_coor != "undefined") {
                        tenth_latlng.push(data_each_coor);
                    }
                }

                for (let item3 of tenth_latlng) {
                    if (
                        item3 &&
                        typeof item3[0] != "undefined" &&
                        typeof item3[1] != "undefined"
                    ) {
                        if (item3[1] > maxLat) maxLat = item3[1];
                        if (item3[0] > maxLng) maxLng = item3[0];
                        if (item3[1] < minLat) minLat = item3[1];
                        if (item3[0] < minLng) minLng = item3[0];
                    }
                }
            }
        }
    }
    return {
        maxLat: maxLat,
        maxLng: maxLng,
        minLat: minLat,
        minLng: minLng,
    };
}

const initMap = () => {
    document.getElementById('heatmap-wrapper').innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
    let map = L.map("map", {
        zoomSnap: 0.2,
        zoomDelta: 0.2,
        scrollWheelZoom: false,
        keyboard: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        fadeAnimation: false,
        attributionControl: false,
    });
    let center_map
    if(props.currentLevel == "all"){
        center_map = latLng(13.088706, 100.276598); // Latlng'centroid of Thailand
    }else {
        let lat = getLat(geoData.value),
        lng = getLng(geoData.value);
        center_map = [lat, lng];
    }
    map.setView(center_map, 5.8);
    const minMaxLatLng = new Promise((resolve) => {
        let temp = getMinMaxLatLng(geoData.value);
        resolve(temp);
    });
    minMaxLatLng.then((data) => {
        let _bound = L.latLngBounds(
            [data.maxLat, data.maxLng],
            [data.minLat, data.minLng]
        );
        map.fitBounds(_bound, {padding: [20, 20]});
    });
    minMaxValue.min = Math.min(
        ...geoData.value.map(item => {
            if(item.properties?.fragile_obj)
              return item.properties?.fragile_obj?.[currentDisplayBy.value][fragile_level.value]
            else
              return 0

          })
    )
    minMaxValue.max = Math.max(
        ...geoData.value.map(item => {
            if(item.properties?.fragile_obj)
              return item.properties?.fragile_obj?.[currentDisplayBy.value][fragile_level.value]
            else
              return 0
          })
    )


    geojson = L.geoJson(geoData.value, {
        style: style,
        onEachFeature: onEachFeature
    }).addTo(map);
    let checkHasValue = geoData.value.some(({properties}) => properties?.fragile_obj)
    if(checkHasValue && !(minMaxValue.max === 0 && minMaxValue.min === 0)){
        var legend = L.control({position: 'bottomright'});
        legend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend')
            div.innerHTML +=`
                <div>
                    <div style="display:flex; flex-direction: column;">
                    <div style="margin-bottom: 2px;text-align: right;">จำนวนครัวเรือนเปราะบาง (${props.displayBy == "hh_cnt" ? 'ครัวเรือน' : 'คน'})</div>
                    <div style="min-width: 180px; height: 18px; background: rgb(252, 110, 36);
                        background: -moz-linear-gradient(270deg, rgba(252, 110, 36,1) 0%, rgba(252, 110, 36,0.15) 100%);
                        background: -webkit-linear-gradient(270deg, rgba(252, 110, 36,1) 0%, rgba(252, 110, 36,0.15) 100%);
                        background: linear-gradient(270deg, rgba(252, 110, 36,1) 0%, rgba(252, 110, 36,0.15) 100%);
                        border-radius: .2rem; opacity: 1"></div>
                        <div style="position: relative; display: flex; justify-content: space-between;">
                            <div>${new Intl.NumberFormat().format(minMaxValue.max === minMaxValue.min ? 0 : minMaxValue.min)}</div>
                            <div>${new Intl.NumberFormat().format(minMaxValue.max)}</div>
                        </div>
                    </div>
                </div>`
            return div;
        };
        loadingSuccess.value = true
        legend.addTo(map);
    }
}

watch(() => props.geoData, ()=>{
    loadingSuccess.value = false
    geoData.value = props.geoData
    initMap()
})

watch(() => props.fragileLevel, (val)=>{
    geoData.value = geoData.value.map(item=>{
        let sum = 0
        if(item.properties?.fragile_obj){
            for(let i of props.fragileLevel){
                sum += item.properties.fragile_obj[currentDisplayBy.value][i]
            }
            item.properties.fragile_obj[currentDisplayBy.value].fragile_total = sum
        }
        return item
    })
    initMap()
})

watch(() => props.displayBy, (val) => {
    currentDisplayBy.value = val
    initMap()
})


</script>

<style lang="scss">

.info { 
  padding: 6px 8px; 
  font: 14px/16px Arial, Helvetica, sans-serif; 
  background: white; 
  background: rgba(255,255,255,0.8); 
  box-shadow: 0 0 15px rgba(0,0,0,0.2); 
  border-radius: 5px; 
  font-family: 'Kanit';
} 

.info h4 { margin: 0 0 5px; color: #777; }

.legend { 
  text-align: left; 
  line-height: 18px; 
  color: #555; 
} 

.legend i { 
  width: 18px; 
  height: 18px; 
  float: left; 
  margin-right:8px; 
  opacity: 0.7; 
}
.leaflet-container{
    background: #f5f5f5;
    font-family: 'Kanit';
}
.leaflet-interactive {
    outline: none !important;
    box-shadow: none !important;
}
.heatmap-tooltip{
    
    b{
        font-size: 16px;
        color: #3e3e3e;
        font-weight: 600;
    }
}
.leaflet-touch .leaflet-bar a{
    width: 35px;
    height: 35px;
    line-height: 30px;
}
.fragile-level{
    width: 39px;
    height: 39px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
}
</style>