<template>
  <Transition>
    <div class="fixed w-full bg-dark bg-opacity-30 h-screen top-0 left-0 flex justify-center px-8 select-none z-[9999]" v-show="modelValue" >
      <Transition>
        <div class="bg-white self-start mt-32 max-w-screen-md overflow-hidden" ref="modalinfo">
          <div class="modal-header p-4 pr-0 justify-between">
            <h4 class="md:text-base xs:text-sm md:mr-auto font-bold">{{header}}</h4>
            <button class="btn btn-light border-none shadow-none" @click="handleClose">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
          </div>
          <div class="p-4"><slot/></div>
          <div class="flex justify-end p-4" v-if="showCloseButton">
            <button class="text-white py-2 btn bg-primary-btn" @click="handleClose">ปิด</button>
          </div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import {  ref } from 'vue'

const modalinfo = ref()
const props = defineProps({
  header:{
    type: String,
    default: null
  },
  modalActive: {
    type: Boolean,
    default: false
  },
  showCloseButton: {
    type: Boolean,
    default: false
  },
  modelValue:{
    type: Boolean,
  }
})
const emits = defineEmits(["close-modal", "update:modelValue"])

const handleClose = () =>{
  emits("update:modelValue", false)
}
onClickOutside(modalinfo, handleClose)
</script>

<style lang="scss">
.modal-header{
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, .7);
  padding-left: 1.25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

</style>