<template>
  <div class="w-full" :id="chartId" v-if="!legendOnRight">
    <div :class="{'chartWrap': mobileModeLayout}">
       <div class="h-full" :class="{'mobileContainer' : mobileModeLayout}">
          <div class="containerBody">
            <canvas ref="chartRef" class="mb-3 lg:mb-0 h-full"/>
          </div>
      </div>
    </div>
    <div class="customLegend mt-5" :class="legendBoxStyle">

    </div>
  </div>
  <div class="grid grid-cols-12 gap-2" v-else>
    <div class="pb-0" :class="[columnClass[0],legendPositionClass]">
      <canvas ref="chartRef" :height="height"/>
    </div>
    <div class="pt-0" :id="chartId" :class="[columnClass[1], legendPositionClass]">
      <div class="customLegend" :class="legendBoxStyle"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, inject, watch, onUnmounted } from 'vue'
import Chart from 'chart.js/auto'


const props = defineProps(
  {
    type: {
      type: String,
      required: true,
      default: 'line',
      validator: value => {
        return ['bar'].indexOf(value) !== -1
      }
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    refKey: {
      type: String,
      default: null
    },
    chartId: {
      type: String,
      required: true,
      default: "chartBox"
    },
    legendOnRight:{
      type: Boolean,
      default: false
    },
    sumTotal:{
      type: Number,
      default: 0
    },
    plugins: {
      type: Object,
      default: () => ({})
    },
    customLegend: {
      type: Boolean,
      default: false
    },
    columnClass:{
      type: Array,
      default: ["col-span-5", "col-span-7"]
    },
    legendPosition:{
      type: String,
      default: "center"
    },
    legendBoxStyle:{
      type: String,
      default: ""
    },
    legendInRow:{
      type: Number,
      default: 0
    },
    mobileModeLayout:{
      type: Boolean,
      default: false
    }
  }
)
const isMobileScreen = ref(false)
const chartRef = ref()
const legendPositionClass = ref()
const init = () => {
  const canvas = chartRef.value?.getContext('2d')
  Chart.defaults.font.family = "Kanit"
  Chart.defaults.plugins.legend.labels.usePointStyle = true
  const chart = new Chart(canvas, {
    type: props.type,
    data: JSON.parse(JSON.stringify(props.data)),
    options: props.options,
    plugins: props.plugins
  })

  watch(props, () => {
    chart.data = JSON.parse(JSON.stringify(props.data))
    chart.options = props.options
    chart.plugins = props.plugins
    if(props.mobileModeLayout){
      const barWidth = 40
      const chartCurrId = document.getElementById(props.chartId)
      const chartWrap = chartCurrId.querySelector(".chartWrap")
      const totalBars = chart.data.labels.length
      const heightConst = totalBars <= 2 ? 40 : 20
      if(isMobileScreen.value){
        let mobileHeight = 300
        chartWrap.style.height = `${mobileHeight}px`
        const containerBody = chartCurrId.querySelector(".containerBody")
        if(totalBars >= Math.ceil(chartWrap.clientWidth/barWidth))
          containerBody.style.width = `${barWidth*totalBars}px`
      }
      else{
        // if(totalBars >= 10)
        chartWrap.style.height = `${(barWidth * totalBars) + heightConst}px`
      }
    }
    chart.update()
    if(props.legendPosition){
      if(props.legendPosition == "center")
        legendPositionClass.value = "my-auto"
      else if(props.legendPosition == 'bottom')
        legendPositionClass.value = "mt-auto"

    }

    if(props.customLegend){
      //add custom legend
      const chartBox = document.getElementById(props.chartId)
      const div = chartBox.querySelector(".customLegend")
      const ul = document.createElement('UL')
      if(props.legendInRow)
        ul.classList.add("legend-row")
      chart.legend.legendItems.forEach((dataset, index)=>{
        const text = dataset.text
        const datasetIndex = dataset.datasetIndex
        const bgColor = dataset.fillStyle
        const bColor = dataset.strokeStyle
        const li = document.createElement('LI')
        li.setAttribute('title', text)
        if(props.legendInRow)
          li.style.gridColumn = `span ${props.legendInRow}/span ${props.legendInRow}`

        const divBox = document.createElement('div')
        const spanBox = document.createElement('SPAN')
        spanBox.classList.add("legend-type")
        spanBox.style.borderColor = bColor
        spanBox.style.backgroundColor = bgColor

        const p = document.createElement('P')
        p.classList.add("truncate")
        const textNode = document.createTextNode(text)
    
        ul.appendChild(li)
        divBox.appendChild(spanBox)
        divBox.appendChild(p)
        li.appendChild(divBox)
        p.appendChild(textNode)
      })
      div.replaceChildren(ul)
    }
  })
  // Attach ChartJs instance
  chartRef.value.instance = chart
}

const setSize = () => {
  if (props.width) {
    chartRef.value.setAttribute('width', props.width)
  }

  if (props.height) {
    chartRef.value.setAttribute('height', props.height)
  }
  if (props.minHeight) {
    chartRef.value.setAttribute('height', props.minHeight)
  }
}

const bindInstance = () => {
  if (props.refKey) {
    const bind = inject(`bind[${props.refKey}]`)
    if (bind) {
      bind(chartRef.value)
    }
  }
}
onMounted(() => {
  bindInstance()
  setSize()
  init()
})



</script>
<style lang="scss">
.chartWrap{
  width: 100%;
  height: 300px;
  // height: 200px;
}
.mobileContainer{
  overflow-x: scroll;
  height: 100%;
}
.containerBody{
  height: 100%;
}
</style>
