<template>
  <BarChart
    type="bar"
    :data="data"
    :options="options"
    customLegend
    mobileModeLayout
    legendPosition="bottom"
    :legendInRow="6"
  />
</template>

<script setup>
import {  computed, ref, watch } from 'vue'

const props = defineProps({
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  chartLabel:{
    type: Array,
    default: ()=>[]
  },
  series:{
    type: Array,
    default: ()=> []
  },
  keyAccess:{
    type: String,
  },
  fragileLevel:{
    type: Object
  },
  displayBy: {
    type: String
  }
})
const fragile_level = ref("fragile_total")
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div.custom-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('custom-tooltip')

    const parentDiv = document.createElement('div');
    parentDiv.classList.add('tooltip-wrap')

    tooltipEl.appendChild(parentDiv);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const divTitle = document.createElement('div');
    divTitle.classList.add('tooltip-title')

    titleLines.forEach(title => {
      const text = document.createTextNode(title);
      divTitle.appendChild(text);
    });

    const divBody = document.createElement('div');
    divBody.classList.add('tooltip-body')
    bodyLines.forEach((body, i) => {
      if(body.length){
        const colors = tooltip.labelColors[i];
        const divWrap = document.createElement('div')
        divWrap.classList.add('tooltip-item')
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = 'rgba(254, 254, 254, 0.88)'//colors.borderColor;
        const text = document.createTextNode(body);
        divWrap.appendChild(span)
        divWrap.appendChild(text)
        divBody.appendChild(divWrap);
      }
    });

    const divRoot = tooltipEl.querySelector('div.tooltip-wrap');

    // Remove old children
    while (divRoot.firstChild) {
      divRoot.firstChild.remove();
    }

    // Add new children
    divRoot.appendChild(divTitle);
    divRoot.appendChild(divBody);
  }

  // const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  const position = chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = position.left + window.scrollX + tooltip._eventPosition.x + 'px';
  tooltipEl.style.top = position.top + window.scrollY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
const getComputedData = computed(()=>{
  let temp = []
  if(props.fragileLevel.includes("fragile_lv0")){
    temp.push({
      label: 'เปราะบางระดับ 0',
      barPercentage: 0.6,
      categoryPercentage: 1.0,
      backgroundColor: '#dadbf6',
      hoverBackgroundColor: '#dadbf6',
      data: getSeriesData('fragile_lv0')
    })
  }
  if(props.fragileLevel.includes("fragile_lv1")){
    temp.push(
      {
        label: 'เปราะบางระดับ 1',
        barPercentage: 0.6,
        categoryPercentage: 1.0,
        backgroundColor: '#bebff1',
        hoverBackgroundColor: '#bebff1',
        data: getSeriesData('fragile_lv1')
      }
    )
  }
  if(props.fragileLevel.includes("fragile_lv2")){
    temp.push({
      label: 'เปราะบางระดับ 2',
      barPercentage: 0.6,
      categoryPercentage: 1.0,
      backgroundColor: '#7b7fe6',
      hoverBackgroundColor: '#7b7fe6',
      data: getSeriesData('fragile_lv2')
    })
  }
  if(props.fragileLevel.includes("fragile_lv3")){
    temp.push({
      label: 'เปราะบางระดับ 3',
      barPercentage: 0.6,
      categoryPercentage: 1.0,
      backgroundColor: '#5d62e1',
      hoverBackgroundColor: '#5d62e1',
      data: getSeriesData('fragile_lv3')
    })
  }
  return temp
})
const data = computed(() => {
  return {
    labels: getChartLabel(),
    datasets: getComputedData.value
  }
})
const options = computed(() => {
  return {
    indexAxis: 'y',
    layout: {
        padding: 20
    },
    scales: {
      x: {
        display: false,
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: 14
          }
        },
        // display: false,
        // grid: {
        //   display: false,
        //   drawBorder: false,
        // },
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        enabled: false,
        external: externalTooltipHandler,
        position: 'average',
        callbacks: {
          title: function(tooltipItems, data) {
           let sum = 0;
            tooltipItems.forEach(function(tooltipItem) {
              sum += tooltipItem.raw;
            });
            return `${tooltipItems[0].label} (รวม: ${new Intl.NumberFormat().format(sum)} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'})`;
          },
          label: (tooltipItems) => {
            const data = tooltipItems.chart.data;
            const datasetIndex = tooltipItems.datasetIndex;
            const index = tooltipItems.dataIndex;
            const datasetLabel = data.datasets[datasetIndex].label || "";

            if(data.datasets[datasetIndex].data[index])
              return `${datasetLabel}: ${new Intl.NumberFormat().format(data.datasets[datasetIndex].data[index])} ${props.displayBy == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}`;
            return;
          }
        }
      },
      legend: {
        labels: {
          fontSize: 14,
          boxWidth: 6
        },
        display: false,
        position: "bottom",
        align: "start",
      }
    },
    maintainAspectRatio: false,
    responsive:true,
  }
})

const dataSeriesComputed = computed(()=>{
  if(props.series){
    return JSON.parse(JSON.stringify(props.series)).sort((a, b) =>
      a[props.displayBy][fragile_level.value] > b[props.displayBy][fragile_level.value] ? -1 : 1
    );
  }
  return;
})
const getChartLabel = () =>{
  if(dataSeriesComputed.value){
    return dataSeriesComputed.value.map(x=> x[props.keyAccess])
  }
  return []
}
const getSeriesData = (key) =>{
  if(dataSeriesComputed.value){
    return dataSeriesComputed.value.map(x => x[props.displayBy][key])
  }
  return []
}

</script>
