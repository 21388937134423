import Dropdown from './dropdown/index.vue'
import CaretUpIcon from './icons/Caret.vue'
import BreadcrumbIcon from './icons/BreadcrumbIcon.vue'
import BarChartIcon from './icons/BarChartIcon.vue'
import TableIcon from './icons/TableIcon.vue'
import DatabaseIcon from './icons/DatabaseIcon.vue'
import BarChart from './barchart/index.vue'
import vSelect from 'vue-select'
import CaretDownIcon from './icons/CaretDownIcon.vue'
import MenuIcon from './icons/MenuIcon.vue'
import FilterIcon from './icons/FilterIcon.vue'
import SearchIcon from './icons/SearchIcon.vue'
import MoreVerticalIcon from './icons/MoreVerticalIcon.vue'
import DownloadIcon from './icons/DownloadIcon.vue'
import LoadingOvalIcon from './icons/LoadingOvalIcon.vue'
import InfoIcon from './icons/InfoIcon.vue'
import ArrowRightIcon from './icons/ArrowRightIcon.vue'
import SelectionIcon from './icons/SelectionIcon.vue'
import TippyContent from './tippy-content/Index.vue'
import Modal from './modal-layout/index.vue'

export default app => {
  app.component('Dropdown', Dropdown)
  app.component('CaretUpIcon', CaretUpIcon)
  app.component('BreadcrumbIcon', BreadcrumbIcon)
  app.component('BarChartIcon', BarChartIcon)
  app.component('TableIcon', TableIcon)
  app.component('DatabaseIcon', DatabaseIcon)
  app.component('BarChart', BarChart)
  app.component('v-select', vSelect)
  app.component('CaretDownIcon', CaretDownIcon)
  app.component('MenuIcon', MenuIcon)
  app.component('FilterIcon', FilterIcon)
  app.component('SearchIcon', SearchIcon)
  app.component('MoreVerticalIcon', MoreVerticalIcon)
  app.component('DownloadIcon', DownloadIcon)
  app.component('LoadingOvalIcon', LoadingOvalIcon)
  app.component('InfoIcon', InfoIcon)
  app.component('ArrowRightIcon', ArrowRightIcon)
  app.component('SelectionIcon', SelectionIcon)
  app.component('TippyContent', TippyContent)
  app.component('Modal', Modal)
}